@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
	margin: 0;
	font-family: 'Roboto Slab', serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

.App {
	text-align: center;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

html {
	height: 100%;
}

body {
	height: 100%;
}

#root {
	height: 100%;
}

.App {
	height: 100%;
}

button {
	cursor: pointer;
}




